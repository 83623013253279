// This component has been migrated to V3
// If you make changes to this component, please drop a note in #lp-instui-react-upgrade

import moment from 'moment';
import { isNil } from 'ramda';

export const TIME_PERIODS = [
  {
    key: 'today',
    value: 'Today',
  },
  {
    key: 'yesterday',
    value: 'Yesterday',
  },
  {
    key: 'this_week',
    value: 'This Week',
  },
  {
    key: 'last_week',
    value: 'Last Week',
  },
  {
    key: 'this_month',
    value: 'This Month',
  },
  {
    key: 'last_month',
    value: 'Last Month',
  },
];

export default {
  formatUTC(date) {
    return moment(date).utc();
  },

  format(date, format) {
    return moment(date).format(format);
  },

  nowUTC() {
    return moment().utc().format();
  },

  nowNative() {
    return moment().toDate(); // equal new Date()
  },

  add(date, number, period) {
    return moment(date).add(number, period).format();
  },

  addToNow(number, period) {
    return moment().add(number, period).format();
  },

  diff(from, to, period) {
    const begin = moment(from);
    const finish = moment(to);
    return begin.diff(finish, period);
  },

  fromNow(date, suffix) {
    return moment(date).fromNow(suffix);
  },

  fromNowShorthand(date, suffix) {
    const time = moment(date).fromNow(suffix).split(' ');
    time[0] = time[0].replace('a', '1');
    // returns a short version of moments text
    // ex - a year ago = 1y ago
    // if suffix is true
    // ex - 14 days = 14d
    return `${time[0]}${time[1][0]} ${time[2]}`.trim();
  },

  calendar(date) {
    return moment(date).calendar();
  },

  isSameOrBefore(date, checkDate) {
    return moment(date).isSameOrBefore(checkDate);
  },

  fullDate(value) {
    return moment(value).format('MMM D, YYYY');
  },

  localeFormat(value) {
    if (isNil(value)) return '—';
    return moment(value).format('L');
  },
  localeFormatFromUTC(value) {
    if (isNil(value)) return '—';
    return moment(value).utc().format('L');
  },
  now() {
    return moment().format();
  },
  validate(value) {
    return moment(value).isValid();
  },
  timeFrom(value) {
    return moment(value).fromNow();
  },

  formattedDate(value) {
    return moment(value).format();
  },

  startOfYear(value) {
    return moment(value).startOf('year').format();
  },

  startOfSchoolYear(value) {
    return moment(value).month('July').date(1).format();
  },

  startOfMonth(value) {
    return moment(value).startOf('month').format();
  },

  startOfDay(value, format = null) {
    return moment(value).startOf('day').format(format);
  },

  endOfDay(value, format = null) {
    return moment(value).endOf('day').format(format);
  },

  nowStartOfDay() {
    return moment().startOf('day');
  },

  nowUTCStartOfDay() {
    return moment().utc().startOf('day');
  },

  toDate(date, format, strict) {
    return moment(date, format, strict);
  },

  time(date) {
    return moment(date).format('LT');
  },

  year(date) {
    return moment(date).year();
  },
};
